const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const AppContext = require('../../AppContext');
const HubButton = require('../HubButton');
const ImageWrapper = require('../ImageWrapper');

const HubCard = ({ id, title, image, description, button }) => {
  const { isDesktop } = React.useContext(AppContext);

  return (
    <section className="hub-card">
      <h3 className={classNames('hub-card__title', `hub-card__title--${id}`)}>
        {title}
      </h3>
      <div className="hub-card__image-container">
        <ImageWrapper
          className="hub-card__image"
          alt={image.alt}
          src={isDesktop ? image.desktop.src : image.mobile.src}
          srcSet={isDesktop ? image.desktop.srcSet : image.mobile.srcSet}
          sizes={image.sizes}
        />
      </div>
      <p
        className={classNames(
          'hub-card__description',
          `hub-card__description--${id}`
        )}
      >
        {description}
      </p>
      <HubButton data={button.data} href={button.href} text={button.text} />
    </section>
  );
};

HubCard.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    alt: PropTypes.string,
    desktop: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    }),

    sizes: PropTypes.string,
  }).isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
    data: PropTypes.shape({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,
};

module.exports = HubCard;
