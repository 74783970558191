/* eslint-disable security/detect-object-injection */
/**
 * Component dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const { Form } = require('@andes/form');
const { Button } = require('@andes/button');
const { TextField } = require('@andes/textfield');
const { Image } = require('nordic/image');
const useEventsHandler = require('../../hooks/useEventsHandler');
const SMSMessages = require('../../data/SmsMessages');

/**
 * SmsSender
 */
const SmsSender = ({
  imageBasePath = 'https://http2.mlstatic.com/storage/marketing-performance-landings/commons/flags',
  siteId,
  phoneValidations,
  onSend,
  submitButtonSize,
  type = 'default',
}) => {
  const {
    phoneNumber,
    inputMessage,
    button,
    disabledButton,
    handleSubmit,
    handleChange,
  } = useEventsHandler(phoneValidations, siteId, onSend);
  const { countryCode } = SMSMessages[siteId];
  const { placeholder } = SMSMessages[siteId];
  const { countryFlag } = SMSMessages[siteId];

  return (
    <div
      className={classNames('ui-sms-sender', {
        default: type === 'default',
        slin: type === 'slin',
      })}
    >
      <Form onSubmit={handleSubmit} className="ui-sms-sender__sms">
        <Image
          className="ui-sms-sender__sms-icon"
          src={`${imageBasePath}/${siteId}.png`}
          srcSet={`${imageBasePath}/${siteId}.png 1x,
            ${imageBasePath}/${siteId}@2x.png 2x`}
          onload="on"
          alt={countryFlag}
        />
        <TextField
          id="phone-number"
          className="ui-sms-sender__sms-input"
          value={phoneNumber}
          onChange={handleChange}
          prefix={countryCode}
          placeholder={placeholder}
          modifier={inputMessage.modifier}
          maxLength={phoneValidations.maxPhoneNumberLength}
          help={inputMessage.message}
        />
        <Button
          id="submit-sms"
          className="ui-sms-sender__sms-button"
          size={submitButtonSize}
          type="submit"
          disabled={
            phoneNumber.length <= 0 ||
            inputMessage.modifier === 'indeterminate' ||
            disabledButton
          }
        >
          {button}
        </Button>
      </Form>
    </div>
  );
};

/**
 * SmsSender propTypes
 */
SmsSender.propTypes = {
  phoneValidations: PropTypes.shape({
    maxPhoneNumberLength: PropTypes.number,
    minPhoneNumberLength: PropTypes.number,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  imageBasePath: PropTypes.string,
  onSend: PropTypes.func.isRequired,
  submitButtonSize: PropTypes.string,
  type: PropTypes.string,
};

/**
 * Export SmsSender
 */
module.exports = SmsSender;
